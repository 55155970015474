import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "tagList"];

  initialize() {
    this.tags = [];
    this.clearInput();
  }

  connect() {
    // Load existing tags from the hidden input
    const initialTags = JSON.parse(
      this.element.querySelector("input[type='hidden']").value || "[]"
    );
    this.tags = initialTags;
    this.updateTagList();

    // Clear input value if there are errors on the current page
    const hasError = document.querySelector(".input--error");
    if (hasError) {
      this.clearInput();
    }
  }

  // Listen for keyup event to detect the Enter key
  addTag(event) {
    // Prevent form submission on Enter key press
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submit behavior

      const tag = event.target.value.trim();

      // Check if tag is not empty and not a duplicate in the input field itself
      if (tag && !this.tags.includes(tag)) {
        this.tags.push(tag);
        this.updateTagList();
        this.updateHiddenInput();
        event.target.value = ""; // Clear the input after adding the tag
      }
    }
  }

  removeTag(event) {
    const tagToRemove = event.target.dataset.tag;
    this.tags = this.tags.filter((tag) => tag !== tagToRemove);
    this.updateTagList();
    this.updateHiddenInput();
  }

  updateTagList() {
    // Empty the list before adding new tags
    this.tagListTarget.innerHTML = "";

    // Render tags inside the input field itself
    this.tags.forEach((tag) => {
      const listItem = document.createElement("li");
      listItem.classList.add("tag-item");
      listItem.innerHTML = `
        <span class="tag-name">${tag}</span>
        <button type="button" class="remove-tag" data-tag="${tag}" data-action="click->tag-input#removeTag">
          <svg fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
            <path d="m1 1 4 4m0 0 4 4m-4-4-4 4m4-4 4-4" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.1"/>
          </svg>
        </button>
      `;
      this.tagListTarget.appendChild(listItem);
    });
  }

  updateHiddenInput() {
    const hiddenInput = this.element.querySelector("input[type='hidden']");
    hiddenInput.value = JSON.stringify(this.tags);
  }

  // clear the input value when there's an error
  clearInput() {
    const inputField = this.element.querySelector(".tag-input");
    if (inputField) {
      inputField.value = "";
    }
  }
}
